@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    --fontOne: "Inter", sans-serif;
    font-family: var(--fontOne);
    --fontTwo: 'Space Grotesk', sans-serif;
    
}

