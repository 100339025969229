.wrapper {
  max-width: 390px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

.actions {
  left: 10px;
}

.backButton {
  /* position: absolute; */
  width: 20px;
  height: 20px;
  /* left: 16px;
    top: 50px; */
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay .file {
  display: block;
  max-width: 100%;
  max-height: 67%;

  margin-bottom: 0;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay .dismiss {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  border-radius: 50px;
  justify-content: space-between;
  /* position: absolute; */
  bottom: 10%;
  width: 10px;
  height: 10px;
  left: 0;
  z-index: 999;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  border-radius: 50px;
  color: white;
  justify-content: space-between;
  /* position: absolute; */
  right: 0;
  width: 10px;
  height: 10px;
  bottom: 10%;
  z-index: 999;
}

.participentNames {
  padding-left: 18px;
  width: 219.77px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;

  color: #eaeaea;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
}

.videovww {
  position: absolute;
  width: 90px;
  height: 18px;
  left: 80px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #787878;
}

/* Loading */

.pulsating-circle {
  position: absolute;
  right: 0;
  top: 5px;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
}

.pulsating-circle::before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  left: 13px;
  top: 14px;
  border-radius: 45px;
  background-color: #01a4e9;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle::after {
  content: "";
  position: absolute;
  left: 13px;
  top: 14px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ff0060;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.like-page-bg {
  background: linear-gradient(
      179.99deg,
      rgba(0, 0, 0, 0.2) 0.01%,
      rgba(27, 27, 27, 0.2) 38.64%
    ),
    #171717;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}
