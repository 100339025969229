.imageGallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.image {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.playbtnBg {
  background: rgba(46, 46, 46, 0.81);
  padding: 7px 7px 7px 12px;
  border-radius: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.popup {
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 20px;
}
@media (max-width: 768px) {
  .image {
    flex: 50%;
    max-width: 50%;
  }
}
