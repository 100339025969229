.container {
  width: 100%;
  height: 100%;
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
}

.clientsDiv {
  width: 100%;
  max-width: 1440px;
  margin: 40px 0px 60px 0px;
}

.clientsScrollSection {
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 20px;
  margin-top: 64px;
}

.clientsCards {
  width: 204px;
  flex-shrink: 0;
}

.clientsCards img {
  width: 100%;
  height: 84px;
  object-fit: cover;
}

.clientsCards p {
  margin-top: 12px;
  text-align: center;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.52px;
  opacity: 0.8;
}

.eventsDiv {
  width: 100%;
  max-width: 1440px;
  margin: 60px 0px;
}

.eventsSection {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 32px;
  row-gap: 27px;
}

.eventsCards {
  height: 218px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  border-radius: 8px;
  background: #f5f5f5;
  overflow: hidden;
}

.eventLogoSection {
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.eventLogoSection img {
  width: 140px;
  height: 100px;
  object-fit: contain;
}

.eventDetailSection {
  padding: 20px 24px 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.eventsUsers {
  margin-top: 16px;
  border-radius: 30px;
  background: #fff;
  padding: 8px 16px 6px 16px;
  color: #05156d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

@media (min-width: 640px) and (max-width: 1023px) {

}

@media (max-width: 640px) {


  .clientsDiv {
    margin: 30px 0px 42px 0px;
  }
  .clientsScrollSection {
    margin-top: 32px;
  }

  .clientsCards {
    width: 154px;
  }

  .clientsCards img {
    width: 100%;
    height: 52px;
    object-fit: cover;
  }
}

@media (max-width: 1023px) {
  .eventsDiv {
    margin: 40px 0px;
  }

  .eventsSection {
    margin-top: 40px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 20px;
  }

  .eventsCards {
    height: 144px;
  }

  .eventLogoSection {
    width: 120px;
    height: 100%;
  }

  .eventLogoSection img {
    width: 88px;
    height: 62px;
  }

  .eventDetailSection {
    padding: 16px 18px 16px 12px;
  }

  .eventsUsers {
    margin-top: 12px;
    padding: 6px 16px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 160% */
  }
}
