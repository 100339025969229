.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  z-index: 9999;
  background: rgba(277, 277, 277, 0.8);
  /* padding-top: 78px; */
  overflow-y: auto;
}
