.login {
    display: flex;
    width: 100%;
    height: 80vh;
    background-color: #e7f4f4;
    align-items: center;
    justify-content: center;
}

.login .container {
    display: flex;
    flex-direction: column;
    width: 33.3%;
    padding: 40px 45px;
    background-color: #fff;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
}

.login h2 {
    text-align: center;
    color: #22baa1;
    margin: 0 0 20px;
    font-size: 24px;

}

.fieldDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 13px;
    width: 100%;
}

.fieldDetails input {
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: none !important;
    width: 100%;
    height: 32px;

}

.fieldDetails input:focus {
    outline: 1px solid #ddd;
}


.container button {
    background-color: #22baa0;
    color: #fff;
    border-radius: 50px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 16px 25px;
    font-weight: 600;
    cursor: pointer;
}

.container p {
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
}

.newUser {
    text-decoration: underline;
    font-size: 14px;
    color: #22baa1 !important;
    cursor: pointer;
}