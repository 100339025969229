.dmSerifFont {
  font-family: "DM Serif Display", serif;
}

.elsieFont {
  font-family: "Elsie", serif;
}

.carouselContainer {
  /* width: 100%; */
  /* Adjust as needed */
  /* margin: 0 auto; */
  /* overflow: hidden; */
  /* position: relative; */
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  height: 350px;
}

.carousel {
  white-space: nowrap;
  transition: ease 1000ms;
  width: 350px;
  height: 100%;
  border-radius: 15px;
}

.slide {
  display: inline-block;
  height: 100%;
  width: 350px;
  border-radius: 15px;
  padding-left: 8px;
  padding-right: 8px;
}

.D197FF {
  filter: drop-shadow(0px 0px 24px #d197ff);
}

.FF97E2 {
  filter: drop-shadow(0px 0px 24px #ff97e2);
}

.FFA397 {
  filter: drop-shadow(0px 0px 24px #ffa397);
}

.carouselImage {
  width: 272px;
  height: 340px;
  border-radius: 24px;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
  /* Add transition for smooth sliding */
}

.jobsSectionGradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 45.27%);
}

.popup {
  align-items: center;
  background-color: #000;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 50%;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 100;
}
