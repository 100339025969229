@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Elsie&display=swap');


.dmSerifFont {
  font-family: "DM Serif Display", serif;
}

.elsieFont {
  font-family: "Elsie", serif;
}
