@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.App {
  width: 100%;
  /* max-width: 1440px; */
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.mobileOnly,
.desktopOnly,
.desktopOnlyGrid,
.mobileOnlyFlex,
.mobileOnlyFlexPricing,
.desktopOnlyGridPricing {
  display: none;
}

.containFull {
  width: 100%;
  height: 100%;
}
pre {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
}
.horizontalSeperator {
  width: 100%;
  height: 1px;
  background: #c9c9c9;
}

.dmSerifRegularItalic {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: italic;
}

.closeIconShadow {
  box-shadow: 0px 4px 30px 0px rgba(101, 100, 100, 0.32);
}

.font_bold_24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.font_semibold_40_30 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
}

.font_semibold_40_24 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
}

.font_semibold_40_24_ebGaramond {
  font-family: "EB Garamond", serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
}

.font_semibold_32_24 {
  font-size: 32px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.64px;
}

.font_semibold_32 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.font_semibold_30 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 133.333% */
  letter-spacing: -0.6px;
}

.font_semibold_24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
}

.font_semibold_24_21 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
}

.font_semibold_21_18 {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 123.81% */
  letter-spacing: -0.42px;
}

.font_semibold_21 {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}

.font_semibold_18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.36px;
}

.font_semibold_16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.font_semibold_16_14 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.font_semibold_10 {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
}

.font_medium_18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.font_medium_16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.font_medium_16_14 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.font_medium_13 {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
}

.font_medium_12 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  letter-spacing: -0.24px;
}

.font_normal_18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.font_normal_16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.font_normal_13 {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
}

.font_normal_12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.24px;
}

.font_normal_10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 18px; /* 180% */
  letter-spacing: -0.2px;
}
.dmSerifFont {
  font-family: "DM Serif Display", serif;
}

.elsieFont {
  font-family: "Elsie", serif;
}
.text400 {
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.28px;
  line-height: 21px;
}

.text600 {
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.28px;
  line-height: 19px;
}

.heading1 {
  color: #0d44d7;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.48px;
  text-transform: capitalize;
}

.heading2 {
  color: #0d44d7;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.28px;
}

.paragraph1 {
  color: #8e8d93;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.8px;
}

.paragraph2 {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.28px;
  text-transform: capitalize;
}

.button {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 1023px) {
  .font_semibold_40_30 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 133.333% */
    letter-spacing: -0.6px;
  }

  .font_semibold_40_24 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
  }

  .font_semibold_40_24_ebGaramond {
    font-family: "EB Garamond", serif;
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
  }

  .font_semibold_24_21 {
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 25.2px */
    letter-spacing: -0.42px;
  }

  .font_semibold_21_18 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.36px;
  }

  .font_semibold_16_14 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  .font_medium_16_14 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
  }

  .mobileOnlyFlexPricing {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .heading1 {
    font-size: 36px;
  }

  .paragraph2 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .headersXV {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
  }
}

@media (max-width: 1279px) {
  .mobileOnly {
    display: block;
  }

  .mobileOnlyFlex {
    display: flex;
  }
}

@media (min-width: 1280px) {
  .desktopOnly {
    display: flex;
  }

  .desktopOnlyGrid {
    display: grid;
  }
}

@media (min-width: 1024px) {
  .desktopOnlyGridPricing {
    display: grid;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.containerCenter {
  transform: translate(-50%, -50%);
}

.filterChip {
  border: 1px solid #cacaca;
  padding: 10px 24px;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.boxShadow {
  background: #fcfcfc;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

@media (max-width: 639px) {
  .font_semibold_32_24 {
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.48px;
  }
}
