.popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  background: black;
  height: 100vh;
  z-index: 500;
}
.imageBg {
  width: 100%;
  height: 100%;
  background: rgba(61, 61, 61, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
/* @media screen and (min-width: 600px) {
  .popup {
    position: fixed;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 390px;
    background: black;
    height: calc(100vh - 85px);
    z-index: 500;
  }
} */
.imgLook {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 390px;
  background-color: #1e1e1e;
}

.imgs {
  height: 571px;
  object-fit: cover;
  width: 100%;
}

.backgroud {
  width: 100%;
  height: 125px;
  background: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;
  position: relative;
}
.header {
  position: sticky;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(60px);
  border-radius: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.headerInnerPill {
  width: 100%;
  height: auto;
  padding: 8px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(39, 39, 39, 0.9);
  border-radius: 50px;
}
.backButton {
  height: 32px;
  width: 32px;
  z-index: 1;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid rgba(71, 71, 71, 0.6);
}
.backgroundFilter {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 40px;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  filter: blur(30px);
}

.Backbutton {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 16px;
  top: 50px;
}
.footer {
  position: absolute;
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 10;
}
.descrption_ht {
  width: 100%;
  justify-content: center;
  border-radius: [object Object]px;
  background-color: #171717;
  padding-top: 16px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  position: relative;
  -webkit-border-radius: [object Object]px;
  -moz-border-radius: [object Object]px;
  -ms-border-radius: [object Object]px;
  -o-border-radius: [object Object]px;
}

.participentNames {
  width: 219.77px;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;

  color: #eaeaea;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
}

.like {
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  /* position: absolute; */
  right: 16px;
}

.Share {
  height: 50px;
  width: 50px;
  /* position: absolute; */
  right: 68px;
}

.videovww {
  /* position: absolute; */
  width: 90px;
  height: 18px;
  margin-left: 10px;
  /* font-family: 'SF Pro Text'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #ffffff;
}

.contestName {
  font-style: normal;
  /* font-size: 15px;
  line-height: 18px;  */

  letter-spacing: -0.005em;

  color: #e52599;
}

/* Loading */

.pulsating-circle {
  /* position: absolute; 
  right: 30%;
  top: 60%;
  height: 50px; */
  width: 49.72066879272461px;
  position: absolute;
  right: 16px;
  /* top: 10px; */
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
}

.pulsating-circle::before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #01a4e9;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ff0060;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.overlay .file {
  display: block;
  max-width: 100%;
  max-height: 67%;

  margin-bottom: 0;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay .dismiss {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  border-radius: 50px;
  justify-content: space-between;
  /* position: absolute; */
  bottom: 10%;
  width: 10px;
  height: 10px;
  left: 0;
  z-index: 999;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  border-radius: 50px;
  color: white;
  justify-content: space-between;
  /* position: absolute; */
  right: 0;
  width: 10px;
  height: 10px;
  bottom: 10%;
  z-index: 999;
}
