@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
.hidden {
  animation: slideDown 0.5s ease-in-out forwards;
}

.visible {
  animation: slideUp 0.5s ease-in-out forwards;
}
