.groteskBold {
  font-family: "Space Grotesk", sans-serif;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 118.75% */
  margin-bottom: 10px;
}
.uploadPhotoButton {
  border-radius: 58px;
  background: linear-gradient(91deg, #e3777e 12.33%, #e3777e 93.72%);
  box-shadow: 0px 4px 40px 0px rgba(227, 119, 126, 0.3);
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
