.container {
  box-shadow: 0px 4px 60px 0px #00000029;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 425px;
  padding: 24px 0px 20px 0px;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 60px;
}

.startDemoButton {
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #4a80ff;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  margin-top: 30px;
}
