.container {
  width: 100%;
  height: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
}

.mainDiv {
  width: 100%;
  margin-top: 33px;
  margin-bottom: 62px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* display: grid;
    grid-template-columns: 1fr 1fr; */
  gap: 25px;
}

.childDiv1 {
  flex: 1 1 0%;
}

.noteDiv {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 16px 15px;
  position: relative;
  margin-top: 22px;
  max-width: 441px;
}

.noteDiv div {
  padding: 2px 7px;
  border-radius: 4px;
  background: #3171f6;
  width: fit-content;
  position: absolute;
  top: -10%;
}

.stepsDiv {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
}

.item1 {
  background: #eed5fd;
  border-radius: 16px;
  padding: 16px;
  flex-shrink: 0;
  max-width: 210px;
}
.item1 p {
  max-width: 267px;
}

.item2 {
  background: #d6d5fd;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  max-width: 210px;
}

.item3 {
  background: #f9ffd7;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  max-width: 210px;
}

.item4 {
  background: #fbe1d2;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  max-width: 350px;
}

.childDiv2 {
  display: flex;
  justify-content: center;
  flex: 1 1 0%;
}

.startDemoButton {
  padding: 10px 18px;
  border-radius: 40px;
  background: #3171f6;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  text-wrap: nowrap;
  height: fit-content;
  cursor: pointer;
}

.attendeeButton {
  display: inline-flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #dee8fc;
  background: #dee8fc;
  color: #3565e3;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  width: 110px;
}

.registerBoxShadow {
  box-shadow: 0px 4px 60px 0px #00000029;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 425px;
  padding: 24px 0px 20px 0px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.takeSelfieButton {
  padding: 12px 24px;
  border-radius: 12px;
  background: #4a80ff;
  color: #f6f6f6;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  cursor: pointer;
}

.retakePhotoButton {
  display: inline-flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid rgba(22, 22, 22, 0.1);
  background: rgba(74, 128, 255, 0.2);
  color: #4a80ff;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
  margin-top: 30px;
  cursor: pointer;
}
.inputField {
  display: flex;
  width: 365px;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid rgba(22, 22, 22, 0.1);
  background: #fff;
}

.multiplePhotosContainer {
  box-shadow: 0px 4px 60px 0px #00000029;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
  padding: 24px 32px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.draganddropSection {
  border-radius: 16px;
  border: 1px dashed #838bf1;
  width: 100%;
  height: 100%;
  margin-top: 25px;
  padding: 20px;
}

.draganddropSectionInner {
  min-height: 442px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadingProgress {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 640px) and (max-width: 1023px) {
}

@media (max-width: 639px) {
  .mainDiv {
    margin-top: 22px;
    margin-bottom: 31px;
  }
}

@media (max-width: 1024px) {
  .mainDiv {
    /* grid-template-columns: 1fr; */
    flex-direction: column;
    gap: 44px;
  }

  .childDiv2 {
    justify-content: center;
  }

  .absoluteDiv {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    top: 0;
    padding: 30px 20px;
    width: auto;
  }

  .noteDiv {
    margin-top: 24px;
  }

  .attendeeButton {
    display: none;
  }

  .inputField {
    width: 100%;
    max-width: 320px;
  }

  .multiplePhotosContainer {
    padding: 24px 16px;
  }

  .draganddropSectionInner {
    min-height: 384px;
  }
  .uploadingProgress {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@media (min-width: 1024px) {
  .absoluteDiv {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
  }

  .stepsDiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    column-gap: 20px;
    row-gap: 22px;
  }

  .item1 {
    background: #eed5fd;
    border-radius: 16px;
    padding: 16px;
    max-width: 500px;
    grid-row: 1;
    grid-column: span 2 / span 2;
    min-height: 182px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .item1 p {
    max-width: 267px;
  }

  .item2 {
    background: #d6d5fd;
    border-radius: 16px;
    padding: 16px;
    grid-row: 1;
    grid-column: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 182px;
  }

  .item3 {
    background: #f9ffd7;
    border-radius: 16px;
    padding: 16px;
    grid-row: 2;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 182px;
  }

  .item4 {
    background: #fbe1d2;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    max-width: 500px;
    grid-row: 2;
    grid-column: span 2 / span 2;
    cursor: pointer;
    min-height: 182px;
  }

  .item5 {
    background: #284535;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    min-height: 182px;
    grid-row: 3;
    grid-column: span 3 / span 3;
  }
}
